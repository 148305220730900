const questions = [
  {
    name: "entry.98989191",
    type: "radio",
    title:
      "En 2020, combien de personnes sont décédées d’un accident de la route lié au travail ?",
    subTitle: "1 réponse possible",
    images: { left: "0.png", right: "1.png" },
    questions: [
      { label: "142", value: "142" },
      { label: "219", value: "219" },
      { label: "356", value: "356" },
    ],
  },
  {
    name: "entry.960225544",
    type: "radio",
    title:
      "En 2020, quel est le pourcentage de la mortalité en France, lié à la vitesse excessive ?",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "2.png", right: "2.png" },
    questions: [
      { label: "21 %", value: "21 %" },
      { label: "31 %", value: "31 %" },
      { label: "41 %", value: "41 %" },
    ],
  },
  {
    name: "entry.963010565",
    type: "checkbox",
    title:
      "Quelles sont les trois principales causes d’accidents mortels sur la route ?",
    subTitle: "3 réponses possibles",
    selectMax: 3,
    images: { left: "3.png", right: "3.png" },
    questions: [
      { label: "La vitesse excessive", value: "La vitesse excessive" },
      { label: "Les traversées de vaches", value: "Les traversées de vaches" },
      {
        label: "La consommation de produits stupéfiants",
        value: "La consommation de produits stupéfiants",
      },
      {
        label: "L’écoute trop forte de musique classique",
        value: "L’écoute trop forte de musique classique",
      },
      { label: "L’alcoolémie", value: "L’alcoolémie" },
      {
        label: "La digestion après une raclette",
        value: "La digestion après une raclette",
      },
    ],
  },
  {
    name: "entry.1379184973",
    type: "radio",
    title:
      "Par combien est multiplié le risque d’accident lors du traitement d’un SMS au volant ?",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "4.png", right: "5.png" },
    questions: [
      { label: "Par 13", value: "Par 13" },
      { label: "Par 23", value: "Par 23" },
      { label: "Par 43", value: "Par 43" },
    ],
  },
  {
    name: "entry.776976968",
    type: "checkbox",
    title:
      "Parmi les choix suivants, quels sont les principaux facteurs de somnolence au volant ?",
    subTitle: "3 réponses possibles",
    selectMax: 3,
    images: { left: "5.png", right: "1.png" },
    questions: [
      { label: "La prise de médicaments", value: "La prise de médicaments" },
      {
        label: "La conversation ennuyeuse du co-pilote",
        value: "La conversation ennuyeuse du co-pilote",
      },
      { label: "La consommation d’alcool", value: "La consommation d’alcool" },
      { label: "Les bouchons", value: "Les bouchons" },
      { label: "Le bruit des klaxons", value: "Le bruit des klaxons" },
      {
        label: "La consommation de produits stupéfiants",
        value: "La consommation de produits stupéfiants",
      },
    ],
  },
  {
    name: "entry.1947166495",
    type: "checkbox",
    title:
      "Parmi les choix suivants, quels sont les principaux symptômes de somnolence au volant ?",
    subTitle: "3 réponses possibles",
    selectMax: 3,
    images: { left: "1.png", right: "2.png" },
    questions: [
      { label: "Avoir faim", value: "Avoir faim" },
      { label: "Avoir la nuque raide", value: "Avoir la nuque raide" },
      { label: "Avoir envie de bâiller", value: "Avoir envie de bâiller" },
      {
        label: "Avoir les paupières lourdes",
        value: "Avoir les paupières lourdes",
      },
      { label: "Avoir envie d’éternuer", value: "Avoir envie d’éternuer" },
      { label: "Avoir envie de chanter", value: "Avoir envie de chanter" },
    ],
  },
  {
    name: "entry.269560577",
    type: "radio",
    title:
      "Les médicaments peuvent représenter un risque sur la conduite. Combien existe-t-il de niveaux permettant de les catégoriser ?",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "2.png", right: "3.png" },
    questions: [
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "5", value: "5" },
    ],
  },
  {
    name: "entry.498879116",
    type: "radio",
    title: "Chez Axione, quel est le taux d’alcool (g/litre de sang) toléré ?",
    subTitle: "1 réponse possible",
    images: { left: "3.png", right: "4.png" },
    questions: [
      { label: "0 g/l", value: "0 g/l" },
      { label: "0,5 g/l", value: "0,5 g/l" },
      { label: "2,5 g/l", value: "2,5 g/l" },
    ],
  },
  {
    name: "entry.1070358131",
    type: "checkbox-image",
    title:
      "Parmi les propositions suivantes, sélectionnez les situations pouvant être un facteur de risque.",
    subTitle: "4 réponses possibles",
    selectMax: 4,
    images: { left: "4.png", right: "5.png" },
    questions: [
      { label: "1", value: "1", img: "/9/1.png" },
      { label: "2", value: "2", img: "/9/2.png" },
      { label: "3", value: "3", img: "/9/3.png" },
      { label: "4", value: "4", img: "/9/4.png" },
      { label: "5", value: "5", img: "/9/5.png" },
      { label: "6", value: "6", img: "/9/6.png" },
    ],
  },
  {
    name: "entry.1812514984",
    type: "radio",
    title: "Quand je conduis, je dois :",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "5.png", right: "5.png" },
    questions: [
      { label: "Avoir les bras tendus", value: "Avoir les bras tendus" },
      {
        label: "Avoir mon siège en position allongée",
        value: "Avoir mon siège en position allongée",
      },
      { label: "Mâcher un chewing-gum", value: "Mâcher un chewing-gum" },
      {
        label: "Avoir les jambes fléchies",
        value: "Avoir les jambes fléchies",
      },
      {
        label: "Reposer ma tête sur l’appui-tête",
        value: "Reposer ma tête sur l’appui-tête",
      },
    ],
  },
  {
    name: "entry.2129229086",
    type: "radio",
    title:
      "La bande d’arrêt d’urgence (BAU) sur l’autoroute me permet de m’arrêter si :",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "1.png", right: "1.png" },
    questions: [
      {
        label: "Je veux lire ma carte routière",
        value: "Je veux lire ma carte routière",
      },
      { label: "Je suis en panne", value: "Je suis en panne" },
      {
        label: "Je veux saluer les camions",
        value: "Je veux saluer les camions",
      },
      { label: "Je veux me reposer", value: "Je veux me reposer" },
      { label: "J’ai une envie pressante", value: "J’ai une envie pressante" },
    ],
  },
  {
    name: "entry.96790741",
    type: "checkbox",
    title:
      "Je suis témoin d’un accident de voiture, la victime est dans son véhicule et consciente. Que dois-je faire ?",
    subTitle: "3 réponses possibles",
    selectMax: 3,
    images: { left: "2.png", right: "2.png" },
    questions: [
      {
        label: "Sortir la victime de son véhicule",
        value: "Sortir la victime de son véhicule",
      },
      {
        label: "Rassurer la victime en lui parlant",
        value: "Rassurer la victime en lui parlant",
      },
      {
        label: "Danser la macarena pour la maintenir éveillée",
        value: "Danser la macarena pour la maintenir éveillée",
      },
      {
        label: "Inciter la victime à rester dans le véhicule",
        value: "Inciter la victime à rester dans le véhicule",
      },
      {
        label: "Mettre la victime en position latérale de sécurité",
        value: "Mettre la victime en position latérale de sécurité",
      },
      { label: "Appeler les secours", value: "Appeler les secours" },
      {
        label: "Lui dire qu’elle est gravement blessée",
        value: "Lui dire qu’elle est gravement blessée",
      },
    ],
  },
  {
    name: "entry.1692455653",
    type: "radio",
    title:
      "Quelle distance de sécurité minimale doit être respectée entre deux véhicules (lignes blanches de la bande d’arrêt d’urgence) ?",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "3.png", right: "3.png" },
    questions: [
      { label: "1 trait", value: "1 trait" },
      { label: "2 traits", value: "2 traits" },
      { label: "3 traits", value: "3 traits" },
      {
        label: "8 traits (on n’est jamais trop prudent)",
        value: "8 traits (on n’est jamais trop prudent)",
      },
    ],
  },
  {
    name: "entry.487270973",
    type: "radio",
    title: "Sous quelle condition puis-je passer un coup de fil à vélo ?",
    subTitle: "1 réponse possible",
    selectMax: 1,
    images: { left: "4.png", right: "4.png" },
    questions: [
      {
        label: "Si je tiens mon téléphone à la main",
        value: "Si je tiens mon téléphone à la main",
      },
      {
        label:
          "Si je suis équipé d’écouteurs, d’oreillettes ou d’un casque audio",
        value:
          "Si je suis équipé d’écouteurs, d’oreillettes ou d’un casque audio",
      },
      {
        label: "Si j’appelle pour commander une pizza",
        value: "Si j’appelle pour commander une pizza",
      },
      {
        label:
          "Aucune, en tant que cycliste je ne peux pas téléphoner en route",
        value:
          "Aucune, en tant que cycliste je ne peux pas téléphoner en route",
      },
    ],
  },
  {
    name: "entry.386377682",
    type: "checkbox",
    title:
      "Dans les équipements obligatoires à moto, quels sont ceux permettant d’avoir une tenue vestimentaire adaptée ?",
    subTitle: "3 réponses possibles",
    selectMax: 3,
    images: { left: "5.png", right: "5.png" },
    questions: [
      {
        label: "Le casque",
        value: "Le casque",
      },
      {
        label: "Le duo claquettes-chaussettes",
        value: "Le duo claquettes-chaussettes",
      },
      {
        label: "Les gants",
        value: "Les gants",
      },
      {
        label:
          "Le gilet de haute visibilité, également appelé « gilet jaune », en cas d’accident ou de panne",
        value:
          "Le gilet de haute visibilité, également appelé « gilet jaune », en cas d’accident ou de panne",
      },
      {
        label: "Le pantacourt",
        value: "Le pantacourt",
      },
    ],
  },
  {
    name: "entry.1495183279",
    type: "bonus",
    title: "Envoyez une image pour gagner des points",
    images: { left: "0.png", right: "0.png" },
  },
];

export default questions;
