export default function Finished() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: 'url("/assets/demo/bg.png")',
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    />
  );
}
