import axios from "axios";
import { useState } from "react";
import { sendImage } from "../../api/quizz";
import { SubmitButton } from "../atoms/buttons";
import "./style.css";
export default function BonusQuestion({ data }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(-400);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const uploadImage = async (e) => {
    e.preventDefault();
    setError(null);
    setFile(null);
    if (!e.target.files[0]) return;

    setLoading(true);
    setUploadProgress(-300);
    await timeout(1000);

    if (e.target.files[0].size > 2048000) {
      setError("Le fichier est trop lourd");
      setLoading(false);
      return;
    }

    setUploadProgress(-150);

    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("api_token", process.env.REACT_APP_API_TOKEN);

    try {
      await timeout(1000);
      setFile("https://api.quizz.dev/uploads/filetest.png");
    } catch (err) {
      console.log(err);
      setError("Une erreur est survenue");
    }

    setUploadProgress(0);
    await timeout(1000);
    setSucceeded(true);

    await timeout(7500);
    setLoading(false);
    setSucceeded(false);
  };

  return (
    <div>
      <img src="/assets/da/bonus-icon.png" alt="" className="m-auto mb-5" />
      <h2 className="font-hurme-bold text-center text-xl tracking-widest">
        Tentez de gagner un bonus 5 points
      </h2>
      <p className="text-sm mt-1 text-center mb-2" style={{ color: "grey" }}>
        Étape facultative
      </p>
      <img src="/assets/da/dash.png" alt="" className="m-auto mb-8" />
      <p className="font-hurme-light text-center text-base tracking-widest my-6 max-w-lg m-auto">
        <span className="font-hurme-semibold">
          Augmentez vos chances de gagner un lot avec ces 5 points bonus en
          déposant une photo vous illustrant lors d’une bonne pratique sur le
          thème de la sécurité routière
        </span>
      </p>
      <p className="font-bebas-regular text-center text-base tracking-widest my-6 max-w-lg m-auto">
        Merci de la déposer au format JPEG ou PNG.
      </p>
      {loading && (
        <div
          className="bg-orange absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-3 px-5 text-white w-full flex flex-col items-center "
          style={{ maxWidth: 400 }}
        >
          {succeeded ? (
            <p className="font-hurme-semibold text-white text-center text-base text-lg my-6">
              Votre photo a bien été chargée.
              <br />
              Elle sera examinée par notre équipe Sécurité pour valider vos 5
              points.
              <br />
              Merci d'avoir joué le jeu jusqu'au bout !
            </p>
          ) : (
            <>
              <h1 className="font-bebas-regular tracking-widest">
                En cours de chargement
              </h1>
              <div
                className="loader transition-all duration-500 ease-in-out"
                style={{
                  backgroundImage: "url('/assets/da/loader-bg.png')",
                  backgroundPosition: uploadProgress,
                }}
              />
            </>
          )}
        </div>
      )}
      {file && <input type="hidden" value={file} name={data.name} />}
      <div className="flex justify-center items-center flex-col">
        <input
          type="file"
          onChange={uploadImage}
          className="m-auto"
          required={file ? true : false}
        />
        {error && <p className="text-red-500">{error}</p>}
      </div>
      <label
        className="flex gap-3 items-center justify-center w-full font-hurme-regular mt-5"
        style={{ fontSize: 14 }}
      >
        <input type="checkbox" defaultChecked="true" />
        <p>
          J’accepte que ma photo puisse être utilisée pour des communications
          internes/externes éventuelles.
        </p>
      </label>

      <button
        className={`flex items-center gap-3 text-lg active:animate-pulse font-bebas-regular tracking-widest mt-10 m-auto`}
        id="nextStep"
        type="submit"
        onClick={() => {
          localStorage.setItem("isSent", true);
        }}
      >
        Suivant
        <img
          src="/assets/arrow-button.svg"
          style={{ width: "40px", marginTop: "-3px" }}
          alt="suivant"
        />
      </button>
    </div>
  );
}
