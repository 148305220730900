import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Quizz from "./pages";
import Finished from "./pages/finished";

import SuccessMessage from "./pages/success";
const dayjs = require("dayjs");

function App() {
  console.log("dayjs", dayjs().isAfter("2022-07-01"));
  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route exact path="/" component={Quizz} />
          <Route exact path="/finish" component={Finished} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}

export default App;
