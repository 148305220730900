import "./style.css";

export function PrevButton(props) {
  return (
    <button
      {...props}
      className="flex items-center gap-4 text-lg active:animate-pulse font-bebas-bold tracking-widest"
      id="prevStep"
    >
      <img
        src="/assets/arrow-button.svg"
        className="transform rotate-180 "
        style={{ width: "40px", marginTop: "-3px" }}
        alt="precedent"
      />
      {props.children ? props.children : "Précedent"}
    </button>
  );
}
export function NextButton(props) {
  return (
    <button
      {...props}
      className={`flex items-center gap-3 text-lg active:animate-pulse font-bebas-regular tracking-widest ${
        props.className ? props.className : ""
      }`}
      id="nextStep"
    >
      Suivant
      <img
        src="/assets/arrow-button.svg"
        style={{ width: "40px", marginTop: "-3px" }}
        alt="suivant"
      />
    </button>
  );
}
export function SubmitButton(props) {
  return (
    <button
      {...props}
      className={`flex items-center gap-3 text-lg active:animate-pulse font-bebas-regular tracking-widest ${
        props.className ? props.className : ""
      }`}
      id="nextStep"
      type="submit"
    >
      Confirmer
      <img
        src="/assets/arrow-button.svg"
        style={{ width: "40px", marginTop: "-3px" }}
        alt="suivant"
      />
    </button>
  );
}
