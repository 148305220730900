import { useEffect, useState } from "react";

import "./style.css";

export default function CheckboxQuestion({
  data,
  pageInformations,
  setIsAnswered,
}) {
  const [selectedResponses, setSelectedResponses] = useState([]);

  const handleSelect = (e) => {
    if (e.target.checked) {
      if (selectedResponses.length === data.selectMax) {
        selectedResponses[0].checked = false;
        setSelectedResponses([
          ...selectedResponses.filter(
            (item) => item.value !== selectedResponses[0].value
          ),
          e.target,
        ]);
      } else {
        setSelectedResponses([...selectedResponses, e.target]);
      }
    } else {
      setSelectedResponses([
        ...selectedResponses.filter((item) => item.value !== e.target.value),
      ]);
    }
  };

  useEffect(() => {
    if (selectedResponses.length === data.selectMax) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResponses]);

  return (
    <div>
      <div className="pagination-block font-hurme-bold items-baseline bg-orange">
        <span>
          {pageInformations.currentPage < 10 && "0"}
          {pageInformations.currentPage}
        </span>
        <span>/{pageInformations.totalPages - 1}</span>
      </div>
      <h4 className="question">{data.title}</h4>
      <p className="text-sm mt-3 mb-4" style={{ color: "grey" }}>
        {data.subTitle}
      </p>
      {data.questions.map((question, index) => (
        <div key={index}>
          <label className="font-hurme-regular flex gap-3 items-center custom-radio-container">
            <input
              type="checkbox"
              name={data.name}
              value={question.value}
              className="custom-radio"
              onChange={handleSelect}
            />
            {question.label}
          </label>
        </div>
      ))}
    </div>
  );
}
