import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NextButton } from "../../atoms/buttons";
// function qui verifie si le mail est valide

export default function Welcome({ setGlobalPage, globalPage }) {
  const [page, setPage] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [informations, setInformations] = useState({
    firstname: "",
    lastname: "",
    email: "",
    number: "",
    address: "",
    confirmation: false,
  });
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(() => {
    if (!informations.firstname.length) {
      setIsCompleted(false);
      return;
    }
    if (!informations.lastname.length) {
      setIsCompleted(false);
      return;
    }
    if (!informations.email.length || !validateEmail(informations.email)) {
      setIsCompleted(false);
      return;
    }
    if (!informations.confirmation) {
      setIsCompleted(false);
      return;
    }
    if (!informations.number.length) {
      setIsCompleted(false);
      return;
    }
    if (!informations.address.length) {
      setIsCompleted(false);
      return;
    }
    console.log("validateEmail ->", validateEmail(informations.email));
    setIsCompleted(true);
  }, [informations]);
  return (
    <div
      className={`${page !== 2 ? "bg-white" : "bg-white md:bg-transparent"} ${
        globalPage === 0 ? "block" : "hidden"
      } py-4 md:py-16 px-12 fadeIn border-black transition-all ${
        page !== 0 && page !== 3 ? "border " : "border-0"
      }`}
    >
      <div className={page !== 0 ? "hidden" : "fadeIn"}>
        <h1 className="font-hurme-semibold tracking-widest text-center text-black text-8xl md:text-9xl">
          Quiz
        </h1>
        <img src="/assets/da/dash.png" alt="" className="m-auto -mt-5 mb-8" />
        <div
          className="text-white px-6 pt-2 rounded-full bg-orange m-auto"
          style={{ width: "fit-content", letterSpacing: "5px" }}
        >
          <p className="font-bebas-regular text-2xl text-center">
            mois Santé Sécurité
          </p>
        </div>
        <p
          className="font-bebas-regular text-black text-center px-2 md:px-20 w-max m-auto py-1 mb-16 flex items-start"
          style={{ lineHeight: "20px", letterSpacing: "4px" }}
        >
          DU 1<span className="text-xs mr-1">ER</span> AU 30 JUIN 2022
        </p>

        <div
          style={{ maxWidth: "250px", margin: "auto" }}
          className="flex flex-col gap-1"
        >
          <p className="px-2 py-1 text-center font-hurme-bold text-2xl bg-black text-white">
            Tous concernés par
          </p>
          <p className="px-2 py-1 text-center font-hurme-bold text-2xl bg-orange text-white">
            la Sécurité Routière
          </p>
        </div>

        <div
          className="text-center relative mt-10 m-auto"
          style={{ width: 250 }}
        >
          <div
            className="border-t border-brown absolute top-1/2 left-1/2 transform -translate-x-1/2"
            style={{ width: 250, zIndex: -1 }}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setPage(1);
            }}
            className="bg-brown font-bebas-bold uppercase w-max rounded-full  px-5 pt-1 text-2xl tracking-widest text-white z-10"
            style={{
              letterSpacing: "5px",
            }}
          >
            Go !
          </button>
          <img
            src="/assets/da/traffic_light.svg"
            alt=""
            className="absolute right-0 transform translate-x-full top-1/2 -translate-y-1/2 -mr-4"
          />
        </div>
      </div>

      <div className={page !== 1 ? "hidden" : "fadeIn"}>
        <h2 className="font-hurme-bold text-center text-xl tracking-widest">
          Modalités du jeu concours
        </h2>
        <img src="/assets/da/dash-rules.png" alt="" className="m-auto mb-8" />
        <p className="font-hurme-light text-center text-base tracking-widest my-6 max-w-lg m-auto">
          Obtenez le plus haut score pour pouvoir remporter un des 20 lots en jeu. 
        </p>
        <img src="/assets/da/bonus.svg" alt="bonus" className="m-auto my-7" />
        <p className="font-hurme-semibold text-center text-base tracking-widest my-6 max-w-lg m-auto">
          Cette année, afin de booster vos chances de gagner, un défi photo vous
          est proposé à la fin du quiz.
        </p>
        <p className="font-hurme-light text-center text-base tracking-widest my-6 max-w-lg m-auto">
          En cas d’égalité les gagnants seront tirés au sort pour être
          départagés.
          <br /> Rien ne sert de jouer plusieurs fois, seul votre premier
          résultat compte !
        </p>
        <p className="font-hurme-light text-center text-base tracking-widest mt-3 max-w-lg m-auto flex items-start justify-center">
          Le quiz est ouvert du 1<span className="text-xs mr-1">er</span> au 30
          juin 2022.
        </p>
        <p className="font-hurme-light text-center text-base tracking-widest max-w-lg m-auto">
          Il s’adresse uniquement aux collaborateurs Axione.
          <br /> La durée du quiz est d’environ 5 minutes.
        </p>
        <NextButton
          onClick={(e) => {
            e.preventDefault();
            setPage(2);
          }}
          className="m-auto mt-10"
        />
      </div>

      <div className={page !== 2 ? "hidden" : "fadeIn"}>
        <h2 className="font-hurme-bold text-center text-xl tracking-widest">
          À gagner
        </h2>
        <img src="/assets/da/dash-gift.png" alt="" className="m-auto mb-8" />
        <img
          src="/assets/da/gift.png"
          alt="liste des lots"
          className="m-auto w-full max-w-lg"
        />
        <NextButton
          onClick={(e) => {
            e.preventDefault();
            setPage(3);
          }}
          className="m-auto mt-10"
        />
      </div>

      <div className={page >= 3 ? "w-full fadeIn" : "hidden"}>
        <div className="md:flex gap-4 w-full">
          <input
            name="entry.821373456"
            type="text"
            placeholder="Prénom"
            className="w-full font-hurme-regular py-2 px-7 tracking-widest mb-4 md:mb-0"
            style={{ border: "1px solid #707070" }}
            onChange={(e) => {
              setInformations({ ...informations, firstname: e.target.value });
            }}
            required
          />
          <input
            name="entry.1242171074"
            type="text"
            placeholder="Nom"
            className="w-full font-hurme-regular py-2 px-7 tracking-widest"
            style={{ border: "1px solid #707070" }}
            onChange={(e) => {
              setInformations({ ...informations, lastname: e.target.value });
            }}
            required
          />
        </div>
        <div className="md:flex gap-4 w-full my-7">
          <input
            name="emailAddress"
            type="email"
            placeholder="Adresse mail"
            className="w-full font-hurme-regular py-2 px-7 tracking-widest mb-4 md:mb-0"
            style={{ border: "1px solid #707070" }}
            onChange={(e) => {
              setInformations({ ...informations, email: e.target.value });
            }}
            required
          />

          <input
            name="entry.708693530"
            type="tel"
            placeholder="Numéro de téléphone"
            className="w-full font-hurme-regular py-2 px-7 tracking-widest mb-4 md:mb-0"
            style={{ border: "1px solid #707070" }}
            onChange={(e) => {
              setInformations({ ...informations, number: e.target.value });
            }}
            required
          />
        </div>

        <input
          name="entry.775230524"
          type="adress"
          placeholder="Adresse postale pour envoi de votre lot"
          className="w-full font-hurme-regular py-2 px-7 tracking-widest mb-4 md:mb-0"
          style={{ border: "1px solid #707070" }}
          onChange={(e) => {
            setInformations({ ...informations, address: e.target.value });
          }}
          required
        />

        <div className="mt-3 md:flex justify-end">
          <label className="flex gap-3 items-start xl:ml-2.5 text-sm w-full font-hurme-regular">
            <input
              type="checkbox"
              className="mt-1"
              onChange={(e) => {
                console.log(e.target.checked);
                setInformations({
                  ...informations,
                  confirmation: e.target.checked,
                });
              }}
            />
            <p>
              J'accepte que mes informations soient collectées et utilisées{" "}
              <u>strictement dans le cadre du jeu concours.</u>
            </p>
          </label>
          <div className="w-full flex justify-end">
            <NextButton
              onClick={(e) => {
                e.preventDefault();
                if (isCompleted) {
                  setGlobalPage(1);
                }
              }}
              className={!isCompleted ? "opacity-50" : "h-max"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
