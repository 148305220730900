import { useEffect, useState } from "react";

import Question from "../components/organisms/question";
import Template from "../components/templates";
import questions from "../utils/questions";
import Navigation from "../components/molecules/navigation";
import Welcome from "../components/organisms/landing";
export default function Quizz() {
  const [page, setPage] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false);
  const [imagesTheme, setImagesTheme] = useState({
    left: "",
    right: "",
  });

  useEffect(() => {
    setIsAnswered(false);
    setImagesTheme(
      questions[page - 1]
        ? questions[page - 1].images
        : {
            left: "",
            right: "",
          }
    );
  }, [page]);

  return (
    <Template imagesTheme={imagesTheme}>
      <form method="GET" action="/finish">
        <Welcome setGlobalPage={setPage} globalPage={page} />
        {questions
          ? questions.map((question, index) => (
              <Question
                key={index}
                data={question}
                currentPage={page}
                totalPages={questions.length}
                id={index + 1}
                setIsAnswered={setIsAnswered}
              />
            ))
          : ""}
        {page !== questions.length && (
          <Navigation
            page={page}
            setPage={setPage}
            questionsLength={questions.length}
            isAnswered={isAnswered}
            setIsAnswered={setIsAnswered}
          />
        )}
      </form>
    </Template>
  );
}
