import { NextButton, SubmitButton } from "../atoms/buttons";
export default function Navigation({
  page,
  setPage,
  questionsLength,
  isAnswered,
  setIsAnswered,
}) {
  return (
    <div className="grid justify-between naviation-block w-full mt-7">
      {/* {page > 0 && (
        <PrevButton
          onClick={(e) => {
            e.preventDefault();
            setPage(page - 1);
            setTimeout(() => {
              setIsAnswered(true);
            }, 200);
          }}
        />
      )} */}
      {page < questionsLength && page > 0 && isAnswered ? (
        <NextButton
          onClick={(e) => {
            e.preventDefault();
            if (isAnswered) setPage(page + 1);
          }}
          className="fadeIn"
        />
      ) : (
        ""
      )}
      {page === questionsLength && (
        <SubmitButton
          onClick={() => {
            localStorage.setItem("isSent", true);
          }}
        />
      )}
    </div>
  );
}
