import BonusQuestion from "../molecules/bonus";
import CheckboxQuestion from "../molecules/checkbox";
import CheckboxImageQuestion from "../molecules/checkbox-image";
import RadioQuestion from "../molecules/radio";
import TextQuestion from "../molecules/text";

export default function Question({
  data,
  currentPage,
  id,
  totalPages,
  setIsAnswered,
}) {
  function renderQuestion(input) {
    if (input) {
      if (input.type === "text" || input.type === "email") {
        return (
          <TextQuestion
            data={input}
            setIsAnswered={setIsAnswered}
            pageInformations={{
              currentPage: currentPage,
              totalPages: totalPages,
            }}
          />
        );
      }
      if (input.type === "radio") {
        return (
          <RadioQuestion
            data={input}
            setIsAnswered={setIsAnswered}
            pageInformations={{
              currentPage: currentPage,
              totalPages: totalPages,
            }}
          />
        );
      }
      if (input.type === "checkbox") {
        return (
          <CheckboxQuestion
            data={input}
            setIsAnswered={setIsAnswered}
            pageInformations={{
              currentPage: currentPage,
              totalPages: totalPages,
            }}
          />
        );
      }
      if (input.type === "checkbox-image") {
        return (
          <CheckboxImageQuestion
            data={input}
            setIsAnswered={setIsAnswered}
            pageInformations={{
              currentPage: currentPage,
              totalPages: totalPages,
            }}
          />
        );
      }
      if (input.type === "bonus") {
        return (
          <BonusQuestion
            data={input}
            setIsAnswered={setIsAnswered}
            pageInformations={{
              currentPage: currentPage,
              totalPages: totalPages,
            }}
          />
        );
      }
    }
  }
  return (
    <div
      className={`bg-white py-16 px-5 pt-10 relative border border-black fadeIn ${
        currentPage !== id ? "hidden" : ""
      }
      ${data.type === "checkbox-image" ? "pb-2" : ""}`}
    >
      {renderQuestion(data)}
    </div>
  );
}
