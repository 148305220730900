import "./style.css";
export default function Template({
  children,
  imagesTheme = {
    left: "",
    right: "",
  },
}) {
  return (
    <div className="w-screen h-screen bg-center bg-cover overflow-hidden bg-white ">
      <div className="flex justify-center items-center h-full w-full">
        <img
          src={`/assets/border/left/${
            imagesTheme.left.length ? imagesTheme.left : "0.png"
          }`}
          alt=""
          style={
            imagesTheme.left.length
              ? {
                  transform: "scaleX(1)",
                }
              : {}
          }
          className="absolute w-full xl:block left-image-deco"
        />
        <div className="center-container-form xl:max-w-screen-md">
          <img
            src="/assets/logo.svg"
            alt=""
            className="absolute w-52 md:w-max top-5 right-7 transform md:-translate-x-2/4 md:left-2/4"
          />
          {children}
        </div>
        <img
          src={`/assets/border/right/${
            imagesTheme.right.length ? imagesTheme.right : "0.png"
          }`}
          alt=""
          className="right-image-deco"
        />
      </div>
    </div>
  );
}
