export default function RadioQuestion({
  data,
  pageInformations,
  setIsAnswered,
}) {
  return (
    <div>
      <div className="pagination-block font-hurme-semibold items-baseline bg-orange">
        <span>
          {pageInformations.currentPage < 10 && "0"}
          {pageInformations.currentPage}
        </span>
        <span>/{pageInformations.totalPages - 1}</span>
      </div>
      <h4 className="question">{data.title}</h4>
      <p className="text-sm mt-3 mb-4" style={{ color: "grey" }}>
        {data.subTitle}
      </p>
      {data.questions.map((question, index) => (
        <div key={index}>
          <label
            className="font-hurme-regular flex gap-3 items-center custom-radio-container text-md"
            onClick={() => setIsAnswered(true)}
          >
            <input
              type="radio"
              name={data.name}
              value={question.value}
              className="custom-radio"
            />
            {question.label}
          </label>
        </div>
      ))}
    </div>
  );
}
